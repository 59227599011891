<form class="form" [formGroup]="form_login">
  <div class="title">
    <p>La sesión ha exporado, vuelve a loguearte para continuar.</p>
  </div>
  <mat-form-field appearance="fill" color="accent">
    <mat-label>Correo</mat-label>
    <input type="text" matInput formControlName="email">
    <mat-error *ngIf="form_login.controls['email'].hasError('required')">
      La cuts es <strong>requerida</strong>.
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill" color="accent">
    <mat-label>Contraseña</mat-label>
    <input type="password" matInput formControlName="password">
    <mat-error *ngIf="form_login.controls['password'].hasError('required')">
      La contraseña es <strong>requerida</strong>.
    </mat-error>
  </mat-form-field>
</form>
<div class="botonera">
  <button mat-button (click)="logout()">Cerrar sesión</button>
  <button mat-flat-button (click)="login()" color="accent" [disabled]="form_login.invalid">Iniciar sesión</button>
</div>
