<h1 mat-dialog-title>Subir recibo oficial de pago</h1>
<div mat-dialog-content>
  <div *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 19">
    <p>Elige el recibo de pago. Al dar clic en el boton <b>subir</b>, el documento se enviará<br/>definitivamente.</p><hr>  
    <div class="w-100 pb-3">
      <input class="form-control" type="file" (change)="preview($event)">
    </div>
  </div>
  <form [formGroup]="form_pago" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 19">
    <p *ngIf="type_procedure != 1 && type_procedure != 3 && type_procedure != 2 && type_procedure != 4">Ingresa los datos de pago del impuesto predial.</p>
    <p *ngIf="type_procedure == 1 || type_procedure == 3 || type_procedure == 2 || type_procedure == 4">Ingresa los datos del recibo de pago de la certificación.</p>
    <hr>
    <div class="w-100 pb-3 form">
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 19">
        <mat-label>folio del recibo oficial</mat-label>
        <input type="text" formControlName="serie" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 19">
        <mat-label>linea de captura</mat-label>
        <input type="text" formControlName="identificador" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || statusIdForm == 19">
        <mat-label>Monto</mat-label>
        <input type="number" min="0" formControlName="info" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 4">
        <mat-label>banco</mat-label>
        <input type="text" formControlName="banco" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 19">
        <mat-label>fecha</mat-label>
        <input type="date" formControlName="fecha" matInput>
      </mat-form-field>
    </div>
  </form>
  <form [formGroup]="form_pago_predial" *ngIf="type_procedure == 1 || statusIdForm == 19">
    <p *ngIf="type_procedure == 1">Ingresa los datos de pago del impuesto predial.</p>
    <p *ngIf="type_procedure != 1">Ingresa los datos del recibo de pago de la certificación.</p>
    <hr>
    <div class="w-100 pb-3 form">
      <mat-form-field appearance="fill">
        <mat-label>folio</mat-label>
        <input type="text" formControlName="serie" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>linea de captura</mat-label>
        <input type="text" formControlName="identificador" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="statusIdForm != 19">
        <mat-label>Monto</mat-label>
        <input type="number" min="0" formControlName="monto" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>fecha</mat-label>
        <input type="date" formControlName="fecha" matInput>
      </mat-form-field>
    </div>
  </form>
  <form [formGroup]="form_catastro">
    <p>Ingresa los datos que apareceran en la certificación.</p><hr>
    <div class="w-100 pb-3 form">
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || statusIdForm == 14 || statusIdForm == 19">
        <mat-label>Clave Catastral</mat-label>
        <input type="text" formControlName="clave_catastral" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 3">
        <mat-label>Clave Catastral Anterior</mat-label>
        <input type="text" formControlName="clave_catastral_anterior" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 600px;" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || statusIdForm == 14 || statusIdForm == 19">
        <mat-label>Domicilio Completo</mat-label>
        <textarea formControlName="domicilio_comp" matInput rows="3"></textarea>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 14 || statusIdForm == 19">
        <mat-label>Calle</mat-label>
        <input type="text" formControlName="calle" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || statusIdForm == 14 || statusIdForm == 19">
        <mat-label>Lote</mat-label>
        <input type="text" formControlName="lote" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || statusIdForm == 14 || statusIdForm == 19">
        <mat-label>Manzana</mat-label>
        <input type="text" formControlName="manzana" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 14 || statusIdForm == 19">
        <mat-label>Número Exterior</mat-label>
        <input type="text" formControlName="numero_exterior" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 14 || statusIdForm == 19">
        <mat-label>Número Interior</mat-label>
        <input type="text" formControlName="numero_interior" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 14 || statusIdForm == 19">
        <mat-label>Colonia</mat-label>
        <input type="text" formControlName="colonia" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 14 || statusIdForm == 19">
        <mat-label>Código Postal</mat-label>
        <input type="text" minlength="5" maxlength="5" formControlName="codigo_postal" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || statusIdForm == 19">
        <mat-label>Bimestre Vigencia</mat-label>
        <input type="text" formControlName="bimestre_vigencia" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || statusIdForm == 19">
        <mat-label>Año Vigencia</mat-label>
        <input type="number" min="2022" max="3000" formControlName="anio_vigencia" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 2 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 14 || statusIdForm == 19">
        <mat-label>Propietario</mat-label>
        <input type="text" formControlName="propietario" matInput>
      </mat-form-field>
    </div>
    <p *ngIf="type_procedure != 2 && statusIdForm != 19">Valores y superficie.</p><hr *ngIf="type_procedure != 2  && statusIdForm != 19">
    <div class="w-100 pb-3 form">

      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 14">
        <mat-label *ngIf="type_procedure == 3">Superficie del terreno propio</mat-label>
        <mat-label *ngIf="type_procedure == 1 || statusIdForm == 14">Superficie del terreno</mat-label>
        <mat-label *ngIf="type_procedure == 4">Valor catastral</mat-label>
        <input type="number" formControlName="superficie_terreno_p" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 3 || type_procedure == 4">
        <mat-label *ngIf="type_procedure == 3">Superficie del terreno común</mat-label>
        <mat-label *ngIf="type_procedure == 4">Valor de operación</mat-label>
        <input type="number" formControlName="superficie_terreno_comun" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 14">
        <mat-label *ngIf="type_procedure == 3">Superficie de construcción propio</mat-label>
        <mat-label *ngIf="type_procedure == 1 || statusIdForm == 14">Superficie de construcción</mat-label>
        <mat-label *ngIf="type_procedure == 4">Avalúo</mat-label>
        <input type="number" formControlName="superficie_construccion_p" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 3 || type_procedure == 4">
        <mat-label *ngIf="type_procedure == 3">Superficie de construcción común</mat-label>
        <mat-label *ngIf="type_procedure == 4">Base gravable</mat-label>
        <input type="number" formControlName="superficie_construccion_comun" matInput>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 3 || type_procedure == 4">
        <mat-label *ngIf="type_procedure == 3">Valor del terreno</mat-label>
        <mat-label *ngIf="type_procedure == 1">Impuesto predial</mat-label>
        <mat-label *ngIf="type_procedure == 4">Importe</mat-label>
        <input type="number" formControlName="valor_terreno_p" matInput>
      </mat-form-field>
     <mat-form-field appearance="fill" *ngIf="type_procedure == 4">
        <mat-label>Actualización</mat-label>
        <input type="number" formControlName="valor_terreno_comun" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 3 || type_procedure == 4">
        <mat-label *ngIf="type_procedure == 3">Valor de construcción</mat-label>
        <mat-label *ngIf="type_procedure == 4">Recargos</mat-label>
        <input type="number" formControlName="valor_construccion_p" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="type_procedure == 4">
        <mat-label>Otros</mat-label>
        <input type="number" formControlName="valor_construccion_comun" matInput>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="type_procedure == 1 || type_procedure == 3 || type_procedure == 4 || statusIdForm == 14">
        <mat-label *ngIf="type_procedure == 3 || type_procedure == 4">Total</mat-label>
        <mat-label *ngIf="type_procedure == 1 || statusIdForm == 14">Valor catastral</mat-label>
        <input type="number" formControlName="total" matInput>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="uploadFile(false)">Cancelar</button>
  <button *ngIf="statusIdForm != 14" mat-button [disabled]="form_catastro.invalid || form_pago.invalid || form_pago_predial.invalid  || file.length == 0" (click)="uploadFile(true)" cdkFocusInitial>Subir</button>
  <button *ngIf="statusIdForm == 14" mat-button (click)="uploadFile(true)" cdkFocusInitial>Subir</button>
</div>
