import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/private/services/admin.service';

@Component({
  selector: 'app-permisos',
  templateUrl: './permisos.component.html',
  styleUrls: ['./permisos.component.sass']
})
export class PermisosComponent implements OnInit {

  constructor(
    private aService: AdminService                        
  ) { }

  ngOnInit(): void {
  }

}
