import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AbstractControl } from '@angular/forms';
import { FirebaseError } from '@firebase/util';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginResponse } from '../interfaces/login';
import { ErrorResponse } from '../interfaces/error';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegisterResponse } from '../interfaces/resgister';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private url: string = environment.url;
  private api_key: string = environment.API_KEY;
  private headers: HttpHeaders = new HttpHeaders({ 'x-api-key': this.api_key });


  constructor(
    private http: HttpClient,
    private auth: AngularFireAuth,
    private _snackBar: MatSnackBar,
    private router: Router
    ) { }

    async register(control: AbstractControl) {
      let status: boolean = false;
      const endPoint = `${this.url}/register`;
      try {
        let user = await this.auth.createUserWithEmailAndPassword(control.get('email')?.value, control.get('password')?.value);
        const body = {
          uid: user.user?.uid,
          name: control.get('name')?.value,
          email: control.get('email')?.value,
          password: control.get('password')?.value,
          passwordConfirmation: control.get('rep_password')?.value,
          phone: control.get('telephone')?.value
        };

        let new_user = this.http.post<RegisterResponse>(endPoint, body, {headers: this.headers});
        let respUser = await firstValueFrom(new_user);
        if(respUser.ok) {
          //localStorage.setItem('token', "tokenDemo"); //`${respUser.token}`
          status = true;
        } else {
          await this.auth.signOut();
        respUser.errors?.forEach(element => {
        });
        status = false;
      }
      return status;
    } catch (err) {
      if (err instanceof FirebaseError) {
        let error = (err as FirebaseError);
        switch (error.code) {
          case 'auth/email-already-in-use':
            //this.toast.error('El correo ya está registrado, intenta con otro.');
            alert('El correo ya está registrado, intenta con otro.');
            break;
            default:
              //this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
              alert('Ocurrió un error inesperado, intenta nuevamente.');
              break;
            }
          } else {
            //this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
            alert('Ocurrió un error inesperado, intenta nuevamente.');
          }
          return false;
        }
      }

async login(control: AbstractControl) {
  const endPoint = `${this.url}/login`;
  try {
    let user = await this.auth.signInWithEmailAndPassword(control.get('email')?.value, control.get('password')?.value);
    if(user) {
      let loginResp = this.http.post<LoginResponse | ErrorResponse>(`${endPoint}/${user.user?.uid}`, {}, {headers: this.headers});
      let resp = await firstValueFrom(loginResp);
      if("token" in resp) {
        localStorage.setItem('token', `${resp.token}`);
        localStorage.setItem('authSettings', `${resp.permisos}`);
        return resp;
      } else {
        await this.auth.signOut();
        return false;
      }
    } else {
      await this.auth.signOut();
      return false;
    }
  } catch (err) {
    if (err instanceof FirebaseError) {
      let error = (err as FirebaseError);
      switch (error.code) {
        case 'auth/user-not-found':
          this._snackBar.open('El correo introducido no está registrado.', 'Cerrar');
          break;
        case 'auth/wrong-password':
          this._snackBar.open('La contraseña es incorrecta.', 'Cerrar');
          break;
        case 'auth/too-many-requests':
          this._snackBar.open('El acceso a esta cuenta se ha desactivado temporalmente debido a muchos intentos fallidos de inicio de sesión. Puede restaurarlo inmediatamente restableciendo su contraseña o puede intentarlo de nuevo más tarde.', 'Cerrar');
          break;
        case 'auth/invalid-email':
          this._snackBar.open('El correo no es valido.', 'Cerrar');
          break;
        default:
          this._snackBar.open('Ocurrió un error inesperado, intenta nuevamente.', 'Cerrar');
          break;
      }
    } else {
      await this.auth.signOut();
      this._snackBar.open('Ocurrió un error inesperado, intenta nuevamente.', 'Cerrar');
    }
    return false;
  }
}

  async forgot(control: AbstractControl) {
    //this.toast.loading(true, 'Iniciando sesión');
    const endPoint = `${this.url}/user`;

    try {
      await this.auth.sendPasswordResetEmail(control.get('email')?.value);
      //this.toast.loading(false, 'Iniciando sesión');
      alert('Se ha enviado un correo para restablecer tu contraseña.');
      this.router.navigateByUrl('/public/auth/login');
      //this.toast.success('Se ha enviado un correo para restablecer tu contraseña.');
    } catch (err) {
      if (err instanceof FirebaseError) {
        //this.toast.loading(false, 'Iniciando sesión');
        let error = (err as FirebaseError);
        switch (error.code) {
          case 'auth/user-not-found':
            //this.toast.error('El correo introducido no está registrado.');
            alert('El correo introducido no está registrado.');
            break;
            default:
              //this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
            alert('Ocurrió un error inesperado, intenta nuevamente.');
            break;
          }
        } else {
        //this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
        alert('Ocurrió un error inesperado, intenta nuevamente.');
      }
    }
  }

  async logout() {
    localStorage.clear();
    await this.auth.signOut();
  }

  decodeToken() {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('token') ?? '';
    return helper.decodeToken(token);
  }
}
