import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideMessaging,getMessaging } from '@angular/fire/messaging';
import { provideStorage,getStorage } from '@angular/fire/storage';
import { LoadingComponent } from './common/components/loading/loading.component';
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { DatePipe } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NotesComponent } from './common/components/notes/notes.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmComponent } from './common/components/confirm/confirm.component';
import { ErrorComponent } from './common/components/error/error.component';
import { UploadAdeudoComponent } from './common/components/upload-adeudo/upload-adeudo.component';
import { AnotacionComponent } from './common/anotacion/anotacion.component';
import { LineaCvcComponent } from './common/components/linea-cvc/linea-cvc.component';
import { LineaCtdComponent } from './common/components/linea-ctd/linea-ctd.component';
import { MensajeComponent } from './common/components/mensaje/mensaje.component';
import { PermisosComponent } from './common/components/permisos/permisos.component';
import { ReciboOficialComponent } from './common/components/recibo-oficial/recibo-oficial.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RefreshComponent } from './common/components/refresh/refresh.component';
import { FolioInternoComponent } from './common/components/folio-interno/folio-interno.component';

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    NotesComponent,
    ConfirmComponent,
    ErrorComponent,
    UploadAdeudoComponent,
    AnotacionComponent,
    LineaCvcComponent,
    LineaCtdComponent,
    MensajeComponent,
    PermisosComponent,
    ReciboOficialComponent,
    RefreshComponent,
    FolioInternoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideMessaging(() => getMessaging()),
    provideStorage(() => getStorage()),
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    HttpClientModule,
    MatSnackBarModule,
    PdfViewerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTooltipModule
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
