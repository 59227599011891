<h1 mat-dialog-title>Subir informacion de adeudos</h1>
<div mat-dialog-content>
  <p>Elige el documento con la informacion de los adeudos.<br/>Al dar clic en el boton <b>subir</b>, el tramite se cancelara<br/>definitivamente.</p>
  <div class="w-100">
    <input class="form-control" type="file" (change)="preview($event)">
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="uploadFile(false)">Cancelar</button>
  <button mat-button (click)="uploadFile(true)" cdkFocusInitial>Subir</button>
</div>