import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-folio-interno',
  templateUrl: './folio-interno.component.html',
  styleUrls: ['./folio-interno.component.sass']
})
export class FolioInternoComponent implements OnInit {

  form_folio: FormGroup = this.fb.group({
    folio: [ '00000-00000', [Validators.required] ],
    ok: [ false, [Validators.required] ]
  });

  public data: any;

  constructor(
    private dialogRef: MatDialogRef<FolioInternoComponent>,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
  }

  close(resp: boolean) {
    this.form_folio.get('ok')?.setValue(resp);;
    this.dialogRef.close(this.form_folio.value);
  }

}
