import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-adeudo',
  templateUrl: './upload-adeudo.component.html',
  styleUrls: ['./upload-adeudo.component.sass']
})
export class UploadAdeudoComponent implements OnInit {

  public file: string = '';
  public ext: string = '';
  public bucket: string = environment.firebase.storageBucket;

  constructor(
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<UploadAdeudoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  preview(event: any) {
    if(event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      this.ext = ((this.file as any).name as string).split('.').reverse()[0];
    }
  }

  uploadFile(flag: boolean) {
    this.data.resp = flag;
    if(flag) {
      let ref = this.storage.refFromURL(`gs://${this.bucket}/adeudos-tesoreria/${this.data.folio.replaceAll('/', '-')}/adeudo.${this.ext}`);
      ref.put(this.file).then((task) => {
        if(task.state == 'success') {
          ref.getDownloadURL().subscribe(r => {
            this.data.url = r;
            this.dialogRef.close(this.data);
          });
        }
      });
    } else {
      this.dialogRef.close(this.data);
    }
  }

}
