import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/public/auth/services/auth.service';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.sass']
})
export class RefreshComponent implements OnInit {

  form_login: FormGroup = this.fb.group({
    email: [ null, [Validators.required, Validators.email] ],
    password: [ null, [Validators.required, Validators.minLength(5)] ],
  });

  constructor(
    private fb: FormBuilder,
    private aService: AuthService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<RefreshComponent>
  ) { }

  ngOnInit(): void {
  }

  login() {
    let l =  this.dialog.open(LoadingComponent, { width: '400px', disableClose: true });
    this.aService.login(this.form_login).then( r => {
      l.close();
      if(r) {
        this.dialogRef.close();
        window.location.reload();
      }
    });
  }

  logout() {
    this.aService.logout();
    window.location.reload();
  }

}
