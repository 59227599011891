import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GetPermisosResponse, PermisosResponse } from '../interfaces/user';
import { UsersResponse } from '../interfaces/users';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private url: string = environment.url;

  constructor(
    private http: HttpClient
  ) { }

  getUsers(page?: number, filtro?: number, search?: string, withTrashed?: boolean, sort?: string, direction?: string) {
    const endPoint = `${this.url}/admin`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });
    
    return this.http.get<UsersResponse>(`${endPoint}/user/all/${page}/${filtro}/${search?.replaceAll('/', '%2F')}/${withTrashed}/${sort}/${direction}`, {headers: headers});
  }

  getPermisos(page?: number, filtro?: number, search?: string, withTrashed?: boolean, sort?: string, direction?: string) {
    const endPoint = `${this.url}/admin`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });
    
    return this.http.get<GetPermisosResponse>(`${endPoint}/permission/all/${page}/${filtro}/${search?.replaceAll('/', '%2F')}/${withTrashed}/${sort}/${direction}`, {headers: headers});
  }

  getPermisosByUid(uid: string) {
    const endPoint = `${this.url}/admin`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });
    
    return this.http.get<PermisosResponse>(`${endPoint}/user/permisos/${uid}`, {headers: headers});
  }

  changeEstatus(activate: boolean, user_id: number) {
    const endPoint = `${this.url}/admin`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });
    if(activate) {
      return this.http.post(`${endPoint}/user/restore/${user_id}`, {}, {headers: headers});
    } else {
      return this.http.post(`${endPoint}/user/deactivate/${user_id}`, {}, {headers: headers});
    }
  }

}
