
<h1 mat-dialog-title>Por favor espera...</h1>
<mat-dialog-content>
    <div class="d-flex flex-column justify-content-center mb-3">
        <div class="d-flex justify-content-center">
            <div>
                <p>Estamos procesando la informacion, este proceso podria demorar un poco.</p>
            </div>
        </div>
    </div>
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</mat-dialog-content>