// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_KEY: "fxih0ee6a8e9fhNPd1d2407d0",
  CRYPTO_KEY: "jc9ab428ad36458d2255dbda3dbe593fb3f6b32be921cb37c9db53f02cd2aa",
  JWT_KEY: "2578f7e4310d9cbf4f81309baf0556847dd115e56a51f752add494763bac0c29",
  url: "https://api-tlalnepantla-tesoreria.zurco.tech",
  url_notarios: 'https://notarios-api.zurco.com.mx/api/v2/notarios',
  user_notarios: 'usertest',
  pass_notarios: '12345678',
  api_key_notarios: '63bc29ee6a8e9fhNd1d2407d0ac0',
  firebase: {
    projectId: 'tlalne-701ae',
    appId: '1:233380293840:web:e5a5510b56c961e17542d5',
    storageBucket: 'tlalne-701ae.appspot.com',
    apiKey: 'AIzaSyAya24hOdfk24Ej8d0juM1JuXTBRmB2dws',
    authDomain: 'tlalne-701ae.firebaseapp.com',
    messagingSenderId: '233380293840',
    measurementId: 'G-74N4WW0B1N',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
