import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-linea-cvc',
  templateUrl: './linea-cvc.component.html',
  styleUrls: ['./linea-cvc.component.sass']
})
export class LineaCvcComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LineaCvcComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  close(resp: boolean) {
    this.data.resp = resp;
    this.dialogRef.close(this.data);
  }

}
