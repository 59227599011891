import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FilesResponse } from '../interfaces/files';
import { TramiteResponse } from '../interfaces/tramite';

@Injectable({
  providedIn: 'root'
})
export class TramitesService {

  private url: string = environment.url;
  private url_notarios: string = environment.url_notarios;
  private user_notarios: string = environment.user_notarios;
  private pass_notarios: string = environment.pass_notarios;
  private api_key_notarios: string = environment.api_key_notarios;

  constructor(
    private http: HttpClient
  ) { }

  obtenerTramites(page?: number, filtro?: number, search?: string, withTrashed?: boolean, sort?: string, direction?: string, typeprocedures?: string, status?: number) {
    const endPoint = `${this.url}/tramitesnotarios`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.get<TramiteResponse>(`${endPoint}/requests/all/${page}/${filtro}/${search?.replaceAll('/', '%2F')}/${withTrashed}/${sort}/${direction}/${typeprocedures}/${status}`, {headers: headers});
  }

  obtenerTramitesCTD(page?: number, filtro?: number, search?: string, withTrashed?: boolean, sort?: string, direction?: string, status?: number) {
    const endPoint = `${this.url}/tramitesnotarios/transferdomain`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.get<TramiteResponse>(`${endPoint}/all/${page}/${filtro}/${search?.replaceAll('/', '%2F')}/${withTrashed}/${sort}/${direction}/${status}`, {headers: headers});
  }

  obtenerArchivos(folio: string) {
    const endPoint = `${this.url}/tramitesnotarios`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.post<FilesResponse>(`${endPoint}/${folio.replaceAll('/', '%2F')}`, {}, {headers: headers});
  }

  validarFile(id: number, status: number) {
    const endPoint = `${this.url}/tramitesnotarios`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.post<FilesResponse>(`${endPoint}/${id}/${status}`, {}, {headers: headers});
  }

  updateAnotation(id: number, data: any) {
    const endPoint = `${this.url}/file`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.post<FilesResponse>(`${endPoint}/anotaciones/${id}`, { anotacion: data }, {headers: headers});
  }

  send2Modify(folio: string, annotations: string) {
    const endPoint = `${this.url}/acciones`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.post(`${endPoint}/correcion/${folio.replaceAll('/', '%2F')}`, {anotaciones: annotations}, {headers: headers});
  }

  send2Valid(folio: string) {
    const endPoint = `${this.url}/acciones`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.post(`${endPoint}/validarTramite/${folio.replaceAll('/', '%2F')}`, {}, {headers: headers});
  }

  noAdeudoT(folio: string, folio_interno: string) {
    const endPoint = `${this.url}/acciones`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.post(`${endPoint}/noadeudo/${folio.replaceAll('/', '%2F')}`, { folio_interno }, {headers: headers});
  }

  noAdeudoCT(folio: string, folio_interno: string) {
    const endPoint = `${this.url}/acciones`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.post(`${endPoint}/noadeudo/CT/${folio.replaceAll('/', '%2F')}`, { folio_interno }, {headers: headers});
  }

  refuseT(folio: string, annotations: string) {
    const endPoint = `${this.url}/acciones`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.post(`${endPoint}/cancelartramite/${folio.replaceAll('/', '%2F')}`, {anotaciones: annotations}, {headers: headers});
  }

  genOrdenCipCam(folio: string) {
    const endPoint = `${this.url}/ordenpago`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.post(`${endPoint}/cipcam/${folio.replaceAll('/', '%2F')}`, {}, {headers: headers});
  }

  genOrdenCvc(folio: string, data: any) {
    const endPoint = `${this.url}/ordenpago`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.post(`${endPoint}/clavevalor/${folio.replaceAll('/', '%2F')}`, data, {headers: headers});
  }

  genOrdenCtd(folio: string, data: any) {
    const endPoint = `${this.url}/ordenpago`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.post(`${endPoint}/traslado/${folio.replaceAll('/', '%2F')}`, data, {headers: headers});
  }

  genOrdenTLN(folio: string, data: any) {
    const endPoint = `${this.url}/ordenpago`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });

    return this.http.post(`${endPoint}/tln/${folio.replaceAll('/', '%2F')}`, data, {headers: headers});
  }

  genPreCert(folio: string) {
    const endPoint = `${this.url}/precedula`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });
    return this.http.post(`${endPoint}/${folio.replaceAll('/', '%2F')}`, {}, {headers: headers});
  }

  firmar(folio: string) {
    const endPoint = `${this.url}/firma`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });
    return this.http.post(`${endPoint}/${folio.replaceAll('/', '%2F')}`, {}, {headers: headers});
  }

  firmarCVC(folio: string) {
    const endPoint = `${this.url}/firma/CVC`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });
    return this.http.post(`${endPoint}/${folio.replaceAll('/', '%2F')}`, {}, {headers: headers});
  }

  conciliar(folio: string, data: any) {
    const endPoint = `${this.url}/conciliarpago`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });
    return this.http.post(`${endPoint}/${folio.replaceAll('/', '%2F')}`, data, {headers: headers});
  }

  send2pendientes(folio: string) {
    const endPoint = `${this.url}/acciones`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });
    return this.http.post(`${endPoint}/send2pendientes/${folio.replaceAll('/', '%2F')}`, {}, {headers: headers});
  }

  send2complete(folio: string) {
    const endPoint = `${this.url}/acciones`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });
    return this.http.post(`${endPoint}/send2complete/${folio.replaceAll('/', '%2F')}`, {}, {headers: headers});
  }

  saveCatastro(data: any) {
    const endPoint = `${this.url}/acciones/info-doc`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });
    return this.http.post(`${endPoint}`, data, {headers: headers});
  }

  getInfoCT(data: any) {
    const endPoint = `${this.url}/acciones/info-ct`;
    const token = localStorage.getItem('token');
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${token}` });
    return this.http.post(`${endPoint}`, data, {headers: headers});
  }

  getTokenNotarios() {
    const endPoint = `${this.url_notarios}/token`;
    const headers: HttpHeaders = new HttpHeaders({ 'x-api-key': `${ this.api_key_notarios }` });
    return this.http.post(`${endPoint}`, { cuts: this.user_notarios, password: this.pass_notarios }, {headers: headers});
  }

  getFilesNames(token: string) {
    const endPoint = `${this.url_notarios}/cat-files/all`;
    const headers: HttpHeaders = new HttpHeaders({ 'x-token': `${ token }` });
    return this.http.get(`${endPoint}`, {headers: headers});
  }

}
