<h1 mat-dialog-title>Generar Linea de pago</h1>
<div mat-dialog-content>
  <p>Completa los datos a continuacion.</p>
  <mat-form-field appearance="fill" class="w-100">
    <mat-label>Nombre</mat-label>
    <input matInput [(ngModel)]="data.nombre">
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100">
    <mat-label>Direccion</mat-label>
    <input matInput [(ngModel)]="data.direccion">
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="close(false)">Cancelar</button>
  <button mat-button (click)="close(true)" cdkFocusInitial>Continuar</button>
</div>