import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-anotacion',
  templateUrl: './anotacion.component.html',
  styleUrls: ['./anotacion.component.sass']
})
export class AnotacionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AnotacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  close(resp: boolean) {
    this.data.resp = resp;
    this.dialogRef.close(this.data);
  }

}
