<h1 mat-dialog-title>Agregar motivo de {{ data.motivo }}</h1>
<div mat-dialog-content>
  <p>Escribe el motivo a continuacion.</p>
  <mat-form-field appearance="fill" class="w-100">
    <mat-label>Motivo</mat-label>
    <input matInput [(ngModel)]="data.anotacion">
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="close(false)">Cancelar</button>
  <button mat-button (click)="close(true)" cdkFocusInitial>Continuar</button>
</div>