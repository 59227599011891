import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TramitesService } from 'src/app/private/services/tramites.service';
import { environment } from 'src/environments/environment';
import { Status } from '../../static/status';
import { LoadingComponent } from '../loading/loading.component';
import { RefreshComponent } from '../refresh/refresh.component';
const moment = require('moment');
moment.locale('es-mx');

@Component({
  selector: 'app-recibo-oficial',
  templateUrl: './recibo-oficial.component.html',
  styleUrls: ['./recibo-oficial.component.sass']
})
export class ReciboOficialComponent implements OnInit, AfterContentInit {

  form_pago: FormGroup = this.fb.group({
    folio_externo: ['----', [Validators.required]],
    campo2: ['----', []],
    estatus: ['----', []],
    fecha: ['----', [Validators.required]],
    info: [0, [Validators.required]],
    serie: ['----', [Validators.required]],
    identificador: ['----', [Validators.required]],
    banco: ['----', [Validators.required]],
  });

  form_pago_predial: FormGroup = this.fb.group({
    fecha: ['----', [Validators.required]],
    serie: ['----', [Validators.required]],
    identificador: ['----', [Validators.required]],
    monto: [0, [Validators.required]],
  });

  form_catastro: FormGroup = this.fb.group({
    folio_externo: ['----', [Validators.required]],
    clave_catastral: ['----', [Validators.required]],
    domicilio_comp: ['----', [Validators.required]],
    clave_catastral_anterior: ['----', [Validators.required]],
    calle: ['----', [Validators.required]],
    lote: ['----', []],
    manzana: ['----', []],
    numero_exterior: ['----', []],
    numero_interior: ['----', []],
    colonia: ['----', [Validators.required]],
    codigo_postal: ['----', []],
    bimestre_vigencia: ['----', [Validators.required]],
    anio_vigencia: ['----', [Validators.required]],
    propietario: ['----', [Validators.required]],
    superficie_terreno_p: ['----', [Validators.required]],
    superficie_construccion_p: ['----', [Validators.required]],
    valor_terreno_p: ['----', [Validators.required]],
    valor_construccion_p: ['----', [Validators.required]],
    superficie_terreno_comun: ['----', [Validators.required]],
    superficie_construccion_comun: ['----', [Validators.required]],
    valor_terreno_comun: ['----', [Validators.required]],
    valor_construccion_comun: ['----', [Validators.required]],
    total: ['----', [Validators.required]],
  });

  public file: string = '';
  public ext: string = '';
  public bucket: string = environment.firebase.storageBucket;
  public type_procedure: number = 1;
  public statusIdForm: number = 1;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private tService: TramitesService,
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<ReciboOficialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.getFolio();
    this.form_catastro.get('calle')?.valueChanges.subscribe(x => this.changeElement());
    this.form_catastro.get('numero_exterior')?.valueChanges.subscribe(x => this.changeElement());
    this.form_catastro.get('numero_interior')?.valueChanges.subscribe(x => this.changeElement());
    this.form_catastro.get('lote')?.valueChanges.subscribe(x => this.changeElement());
    this.form_catastro.get('manzana')?.valueChanges.subscribe(x => this.changeElement());
    this.form_catastro.get('colonia')?.valueChanges.subscribe(x => this.changeElement());
    this.form_catastro.get('codigo_postal')?.valueChanges.subscribe(x => this.changeElement());
  }

  changeElement() {
    let domicilio_comp = `Calle: ${this.form_catastro.get('calle')?.value}, N° Ext: ${this.form_catastro.get('numero_exterior')?.value}, N° Int: ${this.form_catastro.get('numero_interior')?.value}, Lt: ${this.form_catastro.get('lote')?.value}, Mz: ${this.form_catastro.get('manzana')?.value}, Col: ${this.form_catastro.get('colonia')?.value}, C.P: ${this.form_catastro.get('codigo_postal')?.value}`;
    this.form_catastro.get('domicilio_comp')?.setValue(domicilio_comp);
  }

  preview(event: any) {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      this.ext = ((this.file as any).name as string).split('.').reverse()[0];
      this.data.filename = `recibo_oficial.${this.ext}`;
    }
  }

  uploadFile(flag: boolean) {
    this.data.resp = flag;
    if (flag) {
      let l = this.dialog.open(LoadingComponent, { width: '400px', disableClose: true });
      this.form_pago.get("campo2")?.setValue(JSON.stringify(this.form_pago_predial.value));
      this.tService.saveCatastro({ predio: this.form_catastro.value, pago: this.form_pago.value }).subscribe({
        next: (resp) => {
          if (this.statusIdForm != 14) {
            let ref = this.storage.refFromURL(`gs://${this.bucket}/recibo-tesoreria/${this.data.folio.replaceAll('/', '-')}/recibo_oficial.${this.ext}`);
            ref.put(this.file).then((task) => {
              if (task.state == 'success') {
                ref.getDownloadURL().subscribe(r => {
                  this.data.url = r;
                  l.close();
                  this.dialogRef.close(this.data);
                });
              }
            });
          } else {
            l.close();
            this.dialogRef.close(this.data);
          }
        },
        complete: () => { },
        error: (err) => {
          l.close();
          if (err.error.message == 'Invalid Token') {
            this.refresh();
          }
        }
      });
    } else {
      this.dialogRef.close(this.data);
    }
  }

  getFolio() {
    const l = this.dialog.open(LoadingComponent, { disableClose: true, width: '400px' });
    this.tService.obtenerArchivos(this.data.folio).subscribe({
      next: (r) => {
        let form_structure = JSON.parse(r.request.form_structure);
        this.type_procedure = parseInt(r.request.type_procedures_id);
        this.statusIdForm = r.request.status_id;

        // Configuracion interna
        this.form_pago.get('folio_externo')?.setValue(r.request.folio_externo);
        this.form_catastro.get('folio_externo')?.setValue(r.request.folio_externo);

        // Datos del recibo oficial
        this.form_pago.get('serie')?.setValue('----');
        this.form_pago.get('identificador')?.setValue('----');
        this.form_pago.get('banco')?.setValue('----');
        this.form_pago.get('fecha')?.setValue(new Date());
        this.form_catastro.get('clave_catastral')?.setValue(r.request.catastro_numero ?? '----');
        this.form_catastro.get('clave_catastral_anterior')?.setValue(form_structure.clave_catastral_original ?? '----');
        this.form_catastro.get('calle')?.setValue(r.request.calle ?? form_structure.calle_ubicacion ?? '----');
        this.form_catastro.get('lote')?.setValue(r.request.lote ?? '----');
        this.form_catastro.get('manzana')?.setValue(r.request.manzana ?? '----');
        this.form_catastro.get('numero_exterior')?.setValue(r.request.numero_exterior ?? form_structure.numero_exterior_predio ?? form_structure.predio_num_ext ?? '----');
        this.form_catastro.get('numero_interior')?.setValue(r.request.numero_interior ?? form_structure.numero_interior_predio ?? form_structure.predio_num_int ?? '----');
        this.form_catastro.get('colonia')?.setValue(r.request.colonia ?? r.request.predio_colonia ?? '----');
        this.form_catastro.get('codigo_postal')?.setValue(r.request.predio_cp ?? form_structure.codigo_postal_predio ?? form_structure.predio_cp ?? '-----');
        let domicilio_comp = `Calle: ${this.form_catastro.get('calle')?.value}, N° Ext: ${this.form_catastro.get('numero_exterior')?.value}, N° Int: ${this.form_catastro.get('numero_interior')?.value}, Lt: ${this.form_catastro.get('lote')?.value}, Mz: ${this.form_catastro.get('manzana')?.value}, Col: ${this.form_catastro.get('colonia')?.value}, C.P: ${this.form_catastro.get('codigo_postal')?.value}`;
        this.form_catastro.get('domicilio_comp')?.setValue(domicilio_comp);
        this.form_catastro.get('bimestre_vigencia')?.setValue(1);
        this.form_catastro.get('anio_vigencia')?.setValue(2023);
        this.form_catastro.get('propietario')?.setValue(r.request.ciudadano_nombre ?? '----');
        this.form_pago_predial.get('identificador')?.setValue(r.request.number_capture_line ?? '----');
        // Valor catastral #CTD
        this.form_catastro.get('superficie_terreno_p')?.setValue(form_structure.valor_catastral || 0);
        // Valor de operación #CTD
        this.form_catastro.get('superficie_terreno_comun')?.setValue(form_structure.valor_monto_operacion || 0);
        // Avalúo #CTD
        this.form_catastro.get('superficie_construccion_p')?.setValue(form_structure.valor_avaluo || 0);
        // Base gravable #CTD
        this.form_catastro.get('superficie_construccion_comun')?.setValue(form_structure.valor_base_gravable || 0);
        // Importe #CTD
        this.form_catastro.get('valor_terreno_p')?.setValue(form_structure.valor_importe || 0);
        // Actualización #CTD
        this.form_catastro.get('valor_terreno_comun')?.setValue(form_structure.valor_actualizacion || 0);
        // Recargos #CTD
        this.form_catastro.get('valor_construccion_p')?.setValue(form_structure.valor_recargo || 0);
        // Otros #CTD
        this.form_catastro.get('valor_construccion_comun')?.setValue(form_structure.valor_subsidio || 0);
        // Total #CTD
        this.form_catastro.get('total')?.setValue(form_structure.valor_total || 0);

        if (true) {
          const l = this.dialog.open(LoadingComponent, { disableClose: true, width: '400px' });
          this.tService.getInfoCT({ folio: this.data.folio }).subscribe((res: any) => {
            l.close();
            if (res.data.Payment_line_information) {
              let data_ct = JSON.parse(res.data.Payment_line_information.campo2);
              this.form_pago_predial.setValue(data_ct);
              this.form_pago.get('fecha')?.setValue(moment(res.data.Payment_line_information.fecha).add(1, 'day').format('YYYY-MM-DD'));
              this.form_pago.get('info')?.setValue(res.data.Payment_line_information.info);
              this.form_pago.get('identificador')?.setValue(res.data.Payment_line_information.identificador);
              this.form_pago.get('serie')?.setValue(res.data.Payment_line_information.serie);
            }
            this.form_catastro.get('superficie_terreno_p')?.setValue(res.result.SUPERFICIE_TERRENO || 0);
            this.form_catastro.get('superficie_construccion_p')?.setValue(res.result.SUPERFICIE_CONSTRUCCION || 0);
            this.form_catastro.get('superficie_terreno_comun')?.setValue(res.result.STERRPROP || 0);
            this.form_catastro.get('superficie_construccion_comun')?.setValue(res.result.SCONSPROP || 0);
            this.form_catastro.get('valor_terreno_p')?.setValue(res.result.VALOR_TERRENO || 0);
            this.form_catastro.get('valor_construccion_p')?.setValue(res.result.VALOR_CONSTRUCCION || 0);
            this.form_catastro.get('valor_terreno_comun')?.setValue(res.result.VTERRPROP || 0);
            this.form_catastro.get('valor_construccion_comun')?.setValue(res.result.VCONSPROP || 0);
            this.form_catastro.get('total')?.setValue(res.result.CALIFICACION || 0);
            this.form_catastro.get('anio_vigencia')?.setValue(res.result.ANIO_VIGENCIA || 2023);
            this.form_catastro.get('bimestre_vigencia')?.setValue(res.result.BIMESTRE_VIGENCIA || 1);
            this.form_catastro.get('manzana')?.setValue(res.result.mzn ?? '----');
            this.form_catastro.get('codigo_postal')?.setValue(res.result.cp ?? '----');
            this.form_catastro.get('calle')?.setValue(res.result.calle ?? '----');
            this.form_catastro.get('clave_catastral')?.setValue(res.result.clave ?? '----');
            this.form_catastro.get('clave_catastral_anterior')?.setValue(res.result.clave_ant ?? '----');
            this.form_catastro.get('colonia')?.setValue(res.result.colonia ?? '----');
            this.form_catastro.get('numero_exterior')?.setValue(res.result.numero ?? '----');
            this.form_catastro.get('numero_interior')?.setValue(res.result.numero_int ?? '----');
            this.form_catastro.get('propietario')?.setValue(res.result.propietario ?? '----');
            this.form_catastro.get('domicilio_comp')?.setValue(res.result.domicilio_comp ?? '----');
            this.form_catastro.get('lote')?.setValue(res.result.lote ?? '----');
          });
        }


      },
      complete: () => { l.close(); },
      error: (err) => {
        if (err.error.message == 'Invalid Token') {
          this.refresh();
        }
      }
    });
  }

  refresh() {
    this.dialog.open(RefreshComponent, { disableClose: true, width: '600px' });
  }

}
